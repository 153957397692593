import { useRouter } from 'next/router';
import { useContext, useState } from 'react';
import { HomepageCollectionsProductCardInterface } from '../../../Interface/HomepageCollectionsInterface';
import {
  ProductCardInterface,
  ProductSectionCardInterface,
} from '../../../Interface/ProductCardInterface';
import { useAnalytics } from '../../../contexts/AnalyticsProvider';
import { SnackbarContext } from '../../../contexts/SnackbarContext';
import { displayCurrencyValue } from '../../../helper/helper';
import { addToCartByProductSKU } from '../../../redux/cartSlice';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { saveEventV3 } from '../../../utils/eventTracking';
import { saveSelectedRecommendations } from '../../../utils/recombee';
import { WE_USER_EVENT_ADDED_TO_CART } from '../../../utils/we';

function useAddToCartByProductSku() {
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { dispatchSnackbar } = useContext(SnackbarContext);
  const { locationDetails } = useAppSelector((state) => state.global);
  const { cleverTap } = useAnalytics();
  const [displayItem, setDisplayItem] = useState<boolean>(true);
  const [animateItem, setAnimateItem] = useState<boolean>(false);

  const addToCartByPSku = async (
    productSku: string,
    quantity: number,
    product:
      | ProductSectionCardInterface
      | ProductCardInterface
      | HomepageCollectionsProductCardInterface
      | {
          image: string;
          mrp: number | null;
          offerId: number;
          platform: string;
          price: number;
          productId: number;
          regionId: number;
          regionName: string;
          slug: string;
          title: string;
          releaseDate?: string;
          isPreReleased?: 0 | 1 | 2;
        }
      | null,
    price?: number,
    recommendationId?: string | null,
    scenario?: string
  ) => {
    setLoading(true);
    if (product && productSku && recommendationId) {
      saveSelectedRecommendations(productSku, recommendationId);
    }
    dispatch(addToCartByProductSKU(productSku, quantity, product, price))
      .then(() => {
        const discount =
          product &&
          product.price &&
          Number(product.mrp) > Number(product.price) &&
          Math.round(
            ((Number(product.mrp) - Number(product.price)) * 100) /
              Number(product.mrp)
          );
        if (product) {
          saveEventV3({
            category: 'product_card',
            action: 'click',
            label: 'add_to_cart',
            properties: scenario ?? '',
            value: [product?.slug],
            jsonData: {
              slug: product.slug,
              productSku: productSku,
            },
            from: router,
          });
          WE_USER_EVENT_ADDED_TO_CART(
            {
              currency: locationDetails.currency ?? '',
              discount: discount ? (discount > 99 ? 99 : discount) : 0,
              imageUrl: product.image,
              productName: product.title,
              pageUrl: product.slug,
              platform: product.platform ?? '',
              productId: product.productId ? product.productId.toString() : '',
              productType: '',
              productUrl: product.slug,
              basePrice: price ? String(price) : '',
              localPrice: displayCurrencyValue(
                price,
                locationDetails.currency_symbol,
                locationDetails.multiplier
              ),
              countries: locationDetails.country_name ?? '',
            },
            cleverTap
          );

          if (router.pathname === '/cart') {
            setAnimateItem(true);
            setTimeout(() => {
              setDisplayItem(false);
            }, 500);
          } else {
            setTimeout(() => {
              setDisplayItem(false);
            }, 1500);
          }
          setLoading(false);
        }
      })
      .catch((err: Error) => {
        dispatchSnackbar({
          type: 'failure',
          payload: err?.message,
        });
        setLoading(false);
      });
  };

  return { addToCartByPSku, loading, displayItem, animateItem };
}

export default useAddToCartByProductSku;
